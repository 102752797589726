<template>
  <ToursList
    :tour-type="TOUR_TYPES.FLIGHTS_ONLY"
    title="GROUP FLIGHTS PACKAGES"
  >
    <template #tourSummary="{ tourItem }">
      <ag-div class="group_tour_wrap">
        <ag-div
          v-for="(item, i) in tourItem.items"
          v-bind:key="i"
          class="inner_group_tour"
        >
          <ag-div class="">
            <ag-div class="head">
              {{ `Route ${i + 1}` }}
            </ag-div>

            <ag-div class="departure_info">
              <ag-div class="inner_info_wrap">
                <ag-div class="date_label"> Departure Date:</ag-div>
                <ag-div class="value">
                  {{ formattedDate(item.flightItemDetails?.departureDatetime) }}
                </ag-div>
              </ag-div>

              <ag-div class="inner_info_wrap">
                <ag-div class="date_label"> Departure:</ag-div>
                <ag-div class="value">
                  {{ item.flightItemDetails?.originCity }}
                </ag-div>
              </ag-div>

              <ag-div class="inner_info_wrap">
                <ag-div class="date_label"> Arrival Date:</ag-div>
                <ag-div class="value">
                  {{ formattedDate(item.flightItemDetails?.arrivalDatetime) }}
                </ag-div>
              </ag-div>

              <ag-div class="inner_info_wrap">
                <ag-div class="date_label"> Arrival:</ag-div>
                <ag-div class="value">
                  {{ item.flightItemDetails?.destinationCity }}
                </ag-div>
              </ag-div>
            </ag-div>
          </ag-div>
        </ag-div>
      </ag-div>
    </template>
  </ToursList>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ToursList from "@/modules/FDG/components/ToursList.vue";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "GroupFlightsList",
  computed: {
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
  },
  methods: {
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },
  },
  components: { ToursList },
});
</script>
