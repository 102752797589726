import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconBox = _resolveComponent("AgIconBox")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgFlightDetailCard = _resolveComponent("AgFlightDetailCard")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgFlightTerminalInfo = _resolveComponent("AgFlightTerminalInfo")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!
  const _component_AgDiv = _resolveComponent("AgDiv")!

  return (_openBlock(), _createBlock(_component_AgDiv, {
    class: "widget_border margin_top_20 margin_bottom_20",
    "test-id": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_accordion, {
        "panel-value": [1, 2],
        class: "flight_accordion_wrap margin_bottom_0",
        title: "flight acc 2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_accordion_panel, null, {
            flightInfo: _withCtx(() => [
              _createVNode(_component_AgRow, { "test-id": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgColumn, {
                    lg: "3",
                    md: "4",
                    sm: "12",
                    "test-id": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgIconBox, {
                        "sub-title": _ctx.ietnaryDetail.itemName,
                        title: _ctx.ietnaryDetail.itemType,
                        icon: "flightDepartureIcon",
                        "test-id": ""
                      }, null, 8 /* PROPS */, ["sub-title", "title"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_AgColumn, {
                    lg: "9",
                    md: "8",
                    sm: "12",
                    "test-id": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgFlightDetailCard, {
                        "airline-code": 
                  _ctx.ietnaryDetail?.flightItemDetails?.segment[0]?.flightNumber
                ,
                        "airline-logo": 
                  _ctx.ietnaryDetail?.flightItemDetails?.segment[0]?.airline?.logo
                ,
                        "airline-name": 
                  _ctx.ietnaryDetail?.flightItemDetails?.segment[0]?.airline?.name
                ,
                        duration: 
                  _ctx.getDurationText(
                    _ctx.ietnaryDetail.flightItemDetails?.departureDatetime,
                    _ctx.ietnaryDetail.flightItemDetails?.arrivalDatetime
                  )
                ,
                        "fare-info": true,
                        "from-destination": 
                  _ctx.ietnaryDetail.flightItemDetails?.originIATACode
                ,
                        "from-time": 
                  _ctx.formattedTime(
                    _ctx.ietnaryDetail.flightItemDetails?.departureDatetime
                  )
                ,
                        "to-destination": 
                  _ctx.ietnaryDetail.flightItemDetails?.destinationIATACode
                ,
                        "to-time": 
                  _ctx.formattedTime(
                    _ctx.ietnaryDetail.flightItemDetails?.arrivalDatetime
                  )
                ,
                        "test-id": ""
                      }, null, 8 /* PROPS */, ["airline-code", "airline-logo", "airline-name", "duration", "from-destination", "from-time", "to-destination", "to-time"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            tabSection: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ietnaryDetail.flightItemDetails?.segment, (segment, i) => {
                return (_openBlock(), _createBlock(_component_ag_div, { key: i }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgHeading, {
                      title: `Departure - ${_ctx.formattedDayAndDate(
                segment.departureDatetime
              )}`,
                      class: "flight_info_green_box margin_top_20",
                      "test-id": "",
                      variant: "h4"
                    }, null, 8 /* PROPS */, ["title"]),
                    _createVNode(_component_AgFlightTerminalInfo, {
                      "destination-airport-name": segment.destination.airport,
                      "destination-city": segment.destination.city,
                      "destination-time": _ctx.formattedTime(segment.arrivalDatetime),
                      duration: 
                _ctx.getDurationText(
                  segment.departureDatetime,
                  segment.arrivalDatetime
                )
              ,
                      "origin-airport-name": segment.origin.airport,
                      "origin-city": segment.origin.city,
                      "origin-time": _ctx.formattedTime(segment.departureDatetime),
                      "test-id": ""
                    }, null, 8 /* PROPS */, ["destination-airport-name", "destination-city", "destination-time", "duration", "origin-airport-name", "origin-city", "origin-time"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}