import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgIcon = _resolveComponent("AgIcon")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgTextFieldDropDownItem = _resolveComponent("AgTextFieldDropDownItem")!
  const _component_AgAutoComplete = _resolveComponent("AgAutoComplete")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_AgTravelerCountItem = _resolveComponent("AgTravelerCountItem")!
  const _component_AgTravelerCountDropdown = _resolveComponent("AgTravelerCountDropdown")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_hotel_selected_info = _resolveComponent("ag-hotel-selected-info")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_hotel_info_card = _resolveComponent("ag-hotel-info-card")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_hotel_info = _resolveComponent("ag-hotel-info")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!
  const _component_PackageDetailModal = _resolveComponent("PackageDetailModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_AgRow, { class: "d-flex justify-content-between align-items-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgColumn, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_heading, {
                  title: "Select City",
                  variant: "label"
                }),
                _createVNode(_component_AgAutoComplete, {
                  "item-label": "label",
                  modelValue: _ctx.searchValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
                  icon: "calendar",
                  items: _ctx.cities,
                  onSelectItem: _ctx.onSelectCity
                }, {
                  dropdownItem: _withCtx(({ itemProps, itemData }) => [
                    _createVNode(_component_AgTextFieldDropDownItem, _mergeProps(itemProps, {
                      onClick: ($event: any) => (_ctx.onSelectCity(itemData.id))
                    }), {
                      default: _withCtx(() => [
                        _createVNode(_component_AgDiv, { class: "flightDropdownWrap" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AgIcon, {
                              class: "flight_icon_wrap",
                              "test-id": "",
                              name: "umrah"
                            }),
                            _createVNode(_component_AgDiv, { class: "airport_name" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(itemData.label) + " ", 1 /* TEXT */),
                                _createVNode(_component_AgDiv, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(itemData.country), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["onClick"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "items", "onSelectItem"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_AgColumn, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "Check In",
                  modelValue: _ctx.checkin_date,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkin_date) = $event)),
                  "min-date": new Date(),
                  date: _ctx.checkin_date,
                  "calendar-name": "checkin",
                  IconName: "calendar",
                  onOnChangeDate: _cache[2] || (_cache[2] = 
            function changeDate(date) {
              _ctx.checkin_date = date;
            }
          )
                }, null, 8 /* PROPS */, ["modelValue", "min-date", "date"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_AgColumn, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "Check Out",
                  modelValue: _ctx.checkout_date,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkout_date) = $event)),
                  "min-date": _ctx.getMinCheckoutDate,
                  date: _ctx.checkout_date,
                  "calendar-name": "checkout",
                  IconName: "calendar",
                  onOnChangeDate: _cache[4] || (_cache[4] = 
            function changeDate(date) {
              _ctx.checkout_date = date;
            }
          )
                }, null, 8 /* PROPS */, ["modelValue", "min-date", "date"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_AgColumn, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_heading, {
                  title: "Traveler",
                  variant: "label"
                }),
                _createVNode(_component_AgTravelerCountDropdown, {
                  "test-id": "",
                  label: `Travelers - ${_ctx.adultCount}`
                }, {
                  Items: _withCtx(() => [
                    _createVNode(_component_AgTravelerCountItem, {
                      name: "adultCount",
                      count: _ctx.adultCount,
                      icon: "adultIcon",
                      "onUpdate:adultCount": _ctx.updateAdultCount,
                      label: "Adult (12+)",
                      "test-id": ""
                    }, null, 8 /* PROPS */, ["count", "onUpdate:adultCount"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_AgRow, { class: "d-flex justify-content-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgColumn, { class: "text-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_AGButton, {
                  variant: "primary",
                  onClick: _ctx.searchHotels
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Search Hotels")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.validateItenary)
      ? (_openBlock(), _createBlock(_component_ag_card, {
          key: 0,
          style: {"padding":"10px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ValidatedItenaryResp, (booking, bookingKey) => {
              return (_openBlock(), _createBlock(_component_ag_div, { key: bookingKey }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_hotel_selected_info, {
                    style: {"margin-top":"1cm"},
                    checkinDate: booking.checkInDate,
                    checkoutDate: booking.checkOutDate,
                    noOfNight: booking.noOfNight,
                    totalCost: booking.totalCost,
                    hotelName: booking.hotelName,
                    roomType: booking.roomType,
                    travelers: booking.travellers
                  }, null, 8 /* PROPS */, ["checkinDate", "checkoutDate", "noOfNight", "totalCost", "hotelName", "roomType", "travelers"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */)),
            (_ctx.validateItenary && _ctx.ValidatedItenaryResp)
              ? (_openBlock(), _createBlock(_component_AgRow, {
                  key: 0,
                  class: "d-flex justify-content-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgColumn, { class: "text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AGButton, {
                          variant: "primary",
                          class: "margin_bottom_10",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openModalForPreview())),
                          "test-id": ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Preview")
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createVNode(_component_ag_card, { style: {"padding":"10px"} }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.responseData?.hotels_detail, (hotel) => {
          return (_openBlock(), _createBlock(_component_ag_div, {
            key: hotel.hotel_id,
            class: "hotel-details",
            style: {"border-color":"grey"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_hotel_info, {
                    hotelName: hotel.hotel_name,
                    hotelDistance: hotel.hotel_meta.distance,
                    cityName: hotel.address
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_row, { style: {"margin":"bottom 0.5cm"} }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hotel.rooms_detail, (room) => {
                            return (_openBlock(), _createBlock(_component_ag_hotel_info_card, {
                              key: room.room_id,
                              class: "room-details",
                              singleBedRates: 
                room.room_rate.per_night_per_bed + ' / Per Night'
              ,
                              doubleBedRates: 
                room.room_rate.per_night_per_room + ' / Per Night'
              ,
                              quantity: room.room_name + ' x' + room.room_rate.room_count,
                              price: hotel.hotel_currency + ' ' + room.room_rate.final_price,
                              stay: room.total_nights + ' Night(s)'
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AGButton, {
                                  class: "full-width",
                                  "test-id": "",
                                  onClick: ($event: any) => (_ctx.openModal(room, hotel))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Book Room")
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["singleBedRates", "doubleBedRates", "quantity", "price", "stay"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["hotelName", "hotelDistance", "cityName"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, _ctx.responseData?.hotels_detail]
    ]),
    _withDirectives(_createVNode(_component_ag_not_found, { description: "modify your search to get FIT results" }, null, 512 /* NEED_PATCH */), [
      [_vShow, !_ctx.responseData?.hotels_detail]
    ]),
    _createVNode(_component_PackageDetailModal, {
      onCloseModal: _ctx.closeModal,
      isOpen: _ctx.showPackageDetailModal,
      "hotel-array": _ctx.dynamicBookings
    }, null, 8 /* PROPS */, ["onCloseModal", "isOpen", "hotel-array"])
  ], 64 /* STABLE_FRAGMENT */))
}