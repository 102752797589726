<template>
  <ag-heading :title="title" class="bg_heading_wrap" variant="h1" />
  <AgCard class="flightFilterWrap" v-if="!isUmrah()">
    <AgDiv class="head"> Filter </AgDiv>
    <AgDiv class="d-flex">
      <AgFilterDropdown label="Currency">
        <template #Items>
          <AgCheckbox
            @click="(e:MouseEvent) => e.stopPropagation()"
            v-for="currency in uniqueCurrencies"
            :key="currency"
            :value="currency"
            :label="currency"
            v-model="selectedCurrencies"
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
      <AgFilterDropdown label="Airlines">
        <template #Items>
          <AgCheckbox
            v-for="airlines in uniqueAirlines"
            :key="airlines"
            :value="airlines"
            :label="airlines"
            v-model="selectedAirlines"
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
      <AgFilterDropdown label="Departure City">
        <template #Items>
          <AgCheckbox
            v-for="city in uniqueCities"
            :key="city"
            :value="city"
            :label="city"
            v-model="selectedCities"
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
      <AgFilterDropdown label="Departure Month">
        <template #Items>
          <AgCheckbox
            v-for="month in uniqueMonths"
            :key="month"
            :value="month"
            :label="month"
            v-model="selectedMonths"
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
    </AgDiv>
  </AgCard>
  <ag-loader v-if="isFetchingFDGList" />
  <ag-card
    v-for="fdg in finalList"
    v-else-if="!isFetchingFDGList && fdgList.length"
    v-bind:key="fdg.id"
    class="margin_top_20 relative"
  >
    <ag-row>
      <ag-column md="9" sm="12" cols="12">
        <ag-heading :title="fdg.title" class="tour_heading_wrap" variant="h2" />

        <ag-div class="main_tour_wrapper">
          <img
            :src="
              fdg.mainImage || 'https://cdn.net3000.ca/images/192x30/hajj.jpg'
            "
            alt="img"
            width="220"
            height="152"
            class="image_wrapper"
          />
          <slot :tourItem="fdg" name="tourSummary"></slot>
        </ag-div>
      </ag-column>

      <ag-column md="3" sm="12" cols="12">
        <ag-div class="price_info_wrap_group">
          <ag-div class="available_seat_wrap">
            <ag-div class="inner_wrap"
              >{{ fdg.bookingInfo.remainingQuantity }} Seats Available
            </ag-div>
          </ag-div>
          <ag-div>
            <ag-div class="start_form">Starting From</ag-div>
            <ag-div class="price_details_wrap">
              <ag-div class="inner_price">
                {{
                  `${fdg.bookingInfo.currency} ${formatNumber(
                    fdg.bookingInfo.price
                  )}`
                }}
              </ag-div>
              <ag-div class="price_details_info"> Per Passenger </ag-div>
            </ag-div>
          </ag-div>

          <ag-div class="margin_top_20 text-center">
            <router-link :to="routToDetailPage(fdg.id)">
              <a-g-button class="full-width" variant="primary">
                VIEW DETAILS</a-g-button
              >
            </router-link>
          </ag-div>
        </ag-div>
      </ag-column>
    </ag-row>
  </ag-card>

  <ag-not-found v-else :description="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IFDG } from "@/interfaces/fdg.interface";
import { IFDGBookingInfo } from "@/interfaces/fdg.interface";
import {
  formatNumber,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";

export default defineComponent({
  name: "ToursList",
  props: {
    tourType: {
      type: String as PropType<TOUR_TYPES>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedCurrencies: [] as string[],
      selectedAirlines: [] as string[],
      selectedCities: [] as string[],
      selectedMonths: [] as string[],
      newLoad: true,
    };
  },
  methods: {
    isUmrah() {
      return this.tourType === TOUR_TYPES.UMRAH;
    },
    routToDetailPage(id: string) {
      let routePath =
        this.tourType === TOUR_TYPES.FLIGHTS_ONLY
          ? PATH.FDG_FLIGHT_DETAIL
          : this.tourType === TOUR_TYPES.UMRAH
          ? PATH.FDG_UMRAH_DETAIL
          : "";
      return formatStringToRoutePath(routePath, { id });
    },
    formatNumber,
  },
  beforeMount() {
    this.$store.dispatch("fetchGroupFlights", this.tourType);
  },
  computed: {
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    filteredFDGlist(): IFDG[] {
      let filteredList = this.fdgList;
      if (this.newLoad === true) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedCurrencies = this.uniqueCurrencies;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAirlines = this.uniqueAirlines;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedCities = this.uniqueCities;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedMonths = this.uniqueMonths;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.newLoad = false;
      filteredList = filteredList.filter((fdg) =>
        this.selectedCurrencies.includes(fdg.bookingInfo.currency)
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedAirlines.includes(
          fdg.items[0].flightItemDetails?.segment[0].airline.name || ""
        )
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedCities.includes(
          fdg.items[0].flightItemDetails?.originCity || ""
        )
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedMonths.includes(
          fdg.items[0].flightItemDetails?.segment[0].departureDatetime?.toLocaleString(
            "default",
            { month: "long", year: "numeric" }
          ) || ""
        )
      );
      return filteredList;
    },
    fdgList(): IFDG[] {
      return this.$store.getters.fdgList;
    },
    finalList() {
      if (this.isUmrah()) {
        return this.fdgList;
      } else {
        return this.filteredFDGlist;
      }
    },
    fdgBookingDetail(): IFDGBookingInfo {
      return this.$store.getters.fdgBookingDetail;
    },
    isFetchingFDGList(): boolean {
      return this.$store.getters.isFetchingFDGList;
    },

    uniqueCurrencies(): string[] {
      const currencies = this.fdgList.map((fdg) => fdg.bookingInfo.currency);
      return [...new Set(currencies)];
    },
    uniqueAirlines(): string[] {
      const airlines = this.fdgList.map(
        (fdg: IFDG) =>
          fdg.items[0].flightItemDetails?.segment[0].airline.name || ""
      );
      return [...new Set(airlines)];
    },
    uniqueCities(): string[] {
      const cities = this.fdgList.map(
        (fdg: IFDG) => fdg.items[0].flightItemDetails?.originCity || ""
      );
      return [...new Set(cities)];
    },
    uniqueMonths(): string[] {
      const today = new Date();
      const sixMonthsLater = new Date();
      sixMonthsLater.setMonth(today.getMonth() + 6);

      const uniqueMonths: Set<string> = new Set();

      while (today <= sixMonthsLater) {
        const monthYearString = `${today.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })}`;
        uniqueMonths.add(monthYearString);
        today.setMonth(today.getMonth() + 1);
      }

      return Array.from(uniqueMonths);
    },
  },
});
</script>
