import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ToursList = _resolveComponent("ToursList")!

  return (_openBlock(), _createBlock(_component_ToursList, {
    "tour-type": _ctx.TOUR_TYPES.FLIGHTS_ONLY,
    title: "GROUP FLIGHTS PACKAGES"
  }, {
    tourSummary: _withCtx(({ tourItem }) => [
      _createVNode(_component_ag_div, { class: "group_tour_wrap" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tourItem.items, (item, i) => {
            return (_openBlock(), _createBlock(_component_ag_div, {
              key: i,
              class: "inner_group_tour"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_div, { class: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_div, { class: "head" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(`Route ${i + 1}`), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_ag_div, { class: "departure_info" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_div, { class: "inner_info_wrap" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "date_label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Departure Date:")
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_ag_div, { class: "value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formattedDate(item.flightItemDetails?.departureDatetime)), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_ag_div, { class: "inner_info_wrap" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "date_label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Departure:")
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_ag_div, { class: "value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.flightItemDetails?.originCity), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_ag_div, { class: "inner_info_wrap" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "date_label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Arrival Date:")
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_ag_div, { class: "value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formattedDate(item.flightItemDetails?.arrivalDatetime)), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_ag_div, { class: "inner_info_wrap" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "date_label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Arrival:")
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_ag_div, { class: "value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.flightItemDetails?.destinationCity), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["tour-type"]))
}