<template>
  <ag-heading class="margin_bottom_10" title="Price Summary" variant="h2" />
  <AgPriceSummary
    v-for="(item, index) in priceInfoCells"
    v-show="true"
    v-bind:key="index"
    :info="item.info"
    :price="item.amount"
    :total-price="item.isTotalPriceCell"
    test-id=""
  />
  <slot name="flightDetailsActionButtons"></slot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IPriceSummary } from "@/modules/FDG/interface/priceSummary.interface";

export default defineComponent({
  name: "PriceSummary",
  props: {
    priceInfoCells: { type: Object as PropType<IPriceSummary[]> },
  },
  methods: {
    // formatCurrency(amount: number) {
    //   return getCurrencyFormatter().format(amount);
    // },
  },
  computed: {
    // showAdultBreakDown() {
    //   const flightDetails: FlightDetailsType =
    //     this.$store.getters.flightDetails || {};
    //   return Number(flightDetails?.adult_count) > 0;
    // },
    // showChildBreakDown() {
    //   const flightDetails: FlightDetailsType =
    //     this.$store.getters.flightDetails || {};
    //   return Number(flightDetails?.child_count) > 0;
    // },
    // showInfantBreakDown() {
    //   const flightDetails: FlightDetailsType =
    //     this.$store.getters.flightDetails || {};
    //   return Number(flightDetails?.infant_count) > 0;
    // },
    // renderAdultPriceSummaryTitle() {
    //   const flightDetails: FlightDetailsType =
    //     this.$store.getters.flightDetails || {};
    //   const legs: FlightLeg[] = flightDetails?.flight_legs || [];
    //   const airline = legs[0]?.segment[0]?.marketing_airline?.name;
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   const price = this.formatCurrency(
    //     Number(
    //       flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare
    //     )
    //   );
    //   return {
    //     label: `${airline} (adult) x ${flightDetails.adult_count}`,
    //     price,
    //   };
    // },
    // renderChildPriceSummaryTitle() {
    //   const flightDetails: FlightDetailsType =
    //     this.$store.getters.flightDetails || {};
    //   const legs: FlightLeg[] = flightDetails?.flight_legs || [];
    //   const airline = legs[0]?.segment[0]?.marketing_airline?.name;
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   const price = this.formatCurrency(
    //     Number(
    //       flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare
    //     )
    //   );
    //   return {
    //     label: `${airline} (child) x ${flightDetails.child_count}`,
    //     price,
    //   };
    // },
    // renderInfantPriceSummaryTitle() {
    //   const flightDetails: FlightDetailsType =
    //     this.$store.getters.flightDetails || {};
    //   const legs: FlightLeg[] = flightDetails?.flight_legs || [];
    //   const airline = legs[0]?.segment[0]?.marketing_airline?.name;
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   const price = this.formatCurrency(
    //     Number(
    //       flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
    //     )
    //   );
    //   return {
    //     label: `${airline} (infant) x ${flightDetails.infant_count}`,
    //     price,
    //   };
    // },
    // renderTotalGrossPrice() {
    //   const flightDetails: FlightDetailsType =
    //     this.$store.getters.flightDetails || {};
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   return this.formatCurrency(Number(flightDetails?.price?.gross_fare));
    // },
  },
});
</script>
