<template>
  <ag-card>
    <AgHotelBookingReview
      :checkin-date="renderDate(leg.hotelItemDetails?.checkIn)"
      :checkout-date="renderDate(leg.hotelItemDetails?.checkOut)"
      :hotel-address="leg.hotelItemDetails?.propertyAddress"
      :hotel-logo="leg.hotelItemDetails?.propertyImage"
      :hotel-name="leg.hotelItemDetails?.propertyName"
      :no-of-night-stay-txt="hotelNumberOfNightStayText"
      :trip-type="leg.itemType"
      room-type="N/A"
      test-id=""
    />
  </ag-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IFDGBookingDetailItem } from "@/interfaces/fdgBookingDetail.interface";
import {
  getFormattedDateTime,
  hotelNumberOfNightStayText,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY,
  FORMAT_HH_SS,
} from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "HotelSummary",
  props: {
    leg: {
      type: Object as PropType<IFDGBookingDetailItem>,
    },
  },
  computed: {
    FORMAT_HH_SS() {
      return FORMAT_HH_SS;
    },
    hotelNumberOfNightStayText() {
      let _checkInDate = this.leg?.hotelItemDetails?.checkIn || new Date();
      let _checkOutDate = this.leg?.hotelItemDetails?.checkOut || new Date();
      return hotelNumberOfNightStayText(_checkInDate, _checkOutDate);
    },
  },
  methods: {
    renderDate: (date: Date, format = FORMAT_DD_MMM_YYYY) => {
      return date ? getFormattedDateTime(date, format) : "";
    },
  },
});
</script>
