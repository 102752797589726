<template>
  <ag-loader v-if="isFetchingFDGBookingDetail" />

  <AgRow v-else-if="!isFetchingFDGBookingDetail && fdgBookingDetail" test-id="">
    <AgColumn lg="9" md="8" test-id="">
      <form @submit="submitForm">
        <!-- Lead contact box starts from here -->
        <AgCard test-id="">
          <AgIconInfoBar
            icon="contactPhoneIcon"
            test-id=""
            title="Contact Details"
          />
          <ag-accordion
            :panel-value="[0]"
            class="flight_accordion_wrap margin_bottom_0"
          >
            <ag-accordion-panel>
              <template #flightInfo>
                <ag-heading
                  class="margin_bottom_0"
                  title="Lead Traveler"
                  variant="h3"
                ></ag-heading>
              </template>
              <template #tabSection>
                <AgRow class="padding_top_20" test-id="">
                  <AgColumn lg="7" md="7" test-id="">
                    <ag-div class="margin_top_20">
                      <AGTextField
                        v-model="formData.bookingCustomer.email"
                        :error="errors[`bookingCustomer.email`]"
                        :value="formData.bookingCustomer.email"
                        label="Email"
                      />
                    </ag-div>

                    <ag-div>
                      <AgPhoneField
                        v-model="formData.bookingCustomer.phone"
                        :error="errors[`bookingCustomer.phone`]"
                        default-country-code="PK"
                        test-id=""
                        @input="
                          function setPhoneNumber(e) {
                            formData.bookingCustomer.phone = e.target.value;
                          }
                        "
                      />
                    </ag-div>
                  </AgColumn>
                  <AgColumn lg="5" md="5" test-id="">
                    <AgTravelDetail
                      :email="formData.bookingCustomer.email"
                      :phone="formData.bookingCustomer.phone"
                      test-id=""
                    >
                      <template #headingArea>
                        <AgHeading
                          class="margin_bottom_5"
                          title="Contact Details"
                          variant="h2"
                        />
                      </template>
                    </AgTravelDetail>
                  </AgColumn>
                </AgRow>
              </template>
            </ag-accordion-panel>
          </ag-accordion>
        </AgCard>
        <!-- Lead contact box ends here -->

        <AgCard
          v-if="fdgBookingDetail?.tourType === TOUR_TYPES.FLIGHTS_ONLY"
          class="d-flex justify-content-end"
        >
          <a-g-button class="margin_right_10" @click="enableFormFilling = true"
            >Fill Form
          </a-g-button>
          <a-g-button
            :is-loading="isConfirmFDGBookingWithoutTravelerDetails"
            @click="submitFormWithoutTravelerDetails"
            >Continue Without Filling
          </a-g-button>
        </AgCard>

        <!-- Traveler detail form starts from here  -->
        <AgDiv v-if="enableFormFilling">
          <AgCard>
            <AgIconInfoBar
              icon="contactTravelerIcon"
              test-id=""
              title="Traveler Details"
            />
          </AgCard>

          <AgCard
            v-for="(passenger, index) in formData.passengers"
            v-bind:key="index"
          >
            <ag-accordion
              :panel-value="[0]"
              class="flight_accordion_wrap margin_bottom_0"
            >
              <ag-accordion-panel>
                <template #flightInfo>
                  <ag-heading
                    :title="`Traveler ${index + 1}: ${PAX_TYPES[passenger.type as keyof typeof PAX_TYPES]}`"
                    class="margin_bottom_0"
                    variant="h3"
                  ></ag-heading>
                </template>
                <template #tabSection>
                  <AgRow class="padding_top_20" test-id="">
                    <AgColumn lg="7" md="7" test-id="">
                      <ag-div
                        v-if="
                          passenger.isPassportImageRequired ||
                          passenger.documents.length
                        "
                      >
                        <ag-heading
                          class="margin_bottom_20"
                          title="Attachments"
                          variant="h3"
                        />

                        <ag-upload-default
                          v-if="passenger.isPassportImageRequired"
                          label="Upload Passport Image"
                          @onFileChange="
                            function onSelectFile(file) {
                              passenger.passportImage = file[0];
                            }
                          "
                        />
                        <ag-div
                          v-if="errors[`passengers[${[index]}].passportImage`]"
                          class="color-red margin_bottom_20"
                        >
                          <p>
                            {{ errors[`passengers[${[index]}].passportImage`] }}
                          </p>
                        </ag-div>

                        <ag-div
                          v-for="(document, docIndex) in passenger.documents"
                          v-bind:key="docIndex"
                        >
                          <ag-upload-default
                            :label="`Upload
                          ${formatWordIntoCapitalize(
                            document.title.replace('_', ' ')
                          )}`"
                            accept="png/jpeg"
                            @onFileChange="
                              function onSelectFile(file: any) {
                                document.file = file[0];
                              }
                            "
                          />

                          <ag-div
                            v-if="
                              errors[
                                `passengers[${[index]}].documents[${[
                                  docIndex,
                                ]}].file`
                              ]
                            "
                            class="color-red margin_bottom_20"
                          >
                            <p>
                              {{
                                formatWordIntoCapitalize(
                                  document.title
                                ).replace("_", " ") +
                                " " +
                                errors[
                                  `passengers[${[index]}].documents[${[
                                    docIndex,
                                  ]}].file`
                                ]
                              }}
                            </p>
                          </ag-div>
                        </ag-div>
                      </ag-div>

                      <ag-heading
                        class="margin_bottom_20"
                        title="Personal Details"
                        variant="h3"
                      />
                      <ag-select
                        v-model="formData.passengers[index].title"
                        :error="errors[`passengers[${[index]}].title`]"
                        :items="PAX_TITLES(passenger.type)"
                        :value="formData.passengers[index].title"
                        label="Title"
                        variant="outlined"
                      />
                      <a-g-text-field
                        v-model="formData.passengers[index].firstName"
                        :error="errors[`passengers[${[index]}].firstName`]"
                        :value="formData.passengers[index].firstName"
                        label="First Name and Middle Name (if any)"
                      />
                      <a-g-text-field
                        v-model="formData.passengers[index].lastName"
                        :error="errors[`passengers[${[index]}].lastName`]"
                        :value="formData.passengers[index].lastName"
                        label="Last Name"
                      />
                      <ag-select
                        v-model="formData.passengers[index].passengerRelation"
                        :error="
                          errors[`passengers[${[index]}].passengerRelation`]
                        "
                        :items="PAX_RELATIONS"
                        :value="formData.passengers[index].passengerRelation"
                        label="Passenger Relation"
                        variant="outlined"
                      />

                      <ag-calendar
                        v-model="formData.passengers[index].dob"
                        :date="formData.passengers[index].dob"
                        :error="errors[`passengers[${[index]}].dob`]"
                        label="Date of Birth"
                        @onChangeDate="
                          function changeDate(date: Date) {
                            formData.passengers[index].dob = date;
                          }
                        "
                      />
                      <a-g-text-field
                        v-if="formData.passengers[index].passportRequired"
                        v-model="formData.passengers[index].passportNumber"
                        :error="errors[`passengers[${[index]}].passportNumber`]"
                        :value="formData.passengers[index].passportNumber"
                        label="Passport Number"
                      />
                      <ag-calendar
                        v-if="formData.passengers[index].passportRequired"
                        v-model="formData.passengers[index].passportExpiry"
                        :date="formData.passengers[index].passportExpiry"
                        :error="errors[`passengers[${[index]}].passportExpiry`]"
                        class="margin_bottom_0"
                        label="Passport Expiry"
                        @onChangeDate="
                          function changeDate(date: Date) {
                            formData.passengers[index].passportExpiry = date;
                          }
                        "
                      />

                      <ag-auto-complete
                        v-model="formData.passengers[index].nationality"
                        :error="errors[`passengers[${[index]}].nationality`]"
                        :items="NATIONALITIES"
                        :value="formData.passengers[index].nationality"
                        label="Nationality"
                        variant="outlined"
                      >
                        <template #dropdownItem="{ itemProps, itemData }">
                          <AgTextFieldDropDownItem v-bind="itemProps">
                            <AgDiv>
                              <AgDiv>
                                <AgDiv>{{ itemData.label }}</AgDiv>
                              </AgDiv>
                            </AgDiv>
                          </AgTextFieldDropDownItem>
                        </template>
                      </ag-auto-complete>
                    </AgColumn>
                    <AgColumn lg="5" md="5" test-id="">
                      <AgTravelDetail
                        :first-name="formData.passengers[index].firstName"
                        :last-name="formData.passengers[index].lastName"
                        :nationality="formData.passengers[index].nationality"
                        :passenger-relation="
                          formData.passengers[index].passengerRelation
                        "
                        :passport-expiry="
                          formattedDate(
                            formData.passengers[index].passportExpiry
                          )
                        "
                        :passport-number="
                          formData.passengers[index].passportNumber
                        "
                        :title="formData.passengers[index].title"
                        test-id=""
                      >
                        <template #headingArea>
                          <AgHeading
                            class="margin_bottom_5"
                            title="Contact Details"
                            variant="h2"
                          />
                        </template>
                      </AgTravelDetail>
                    </AgColumn>
                  </AgRow>
                </template>
              </ag-accordion-panel>
            </ag-accordion>
          </AgCard>

          <AgCard test-id="">
            <AgDiv class="text-right" test-id="">
              <AGButton
                :is-loading="isConfirmFDGBooking"
                test-id=""
                type="submit"
              >
                Confirm Booking
              </AGButton>
            </AgDiv>
          </AgCard>
        </AgDiv>
      </form>
    </AgColumn>
    <AgColumn lg="3" md="4" test-id="">
      <ag-card>
        <PriceSummary :priceInfoCells="getPriceInfo" />
      </ag-card>

      <ag-div v-for="(leg, index) in getLegs" v-bind:key="index">
        <TripSummary v-if="leg.itemType === BOOKING_TYPES.FLIGHT" :leg="leg" />
        <HotelSummary v-if="leg.itemType === BOOKING_TYPES.HOTEL" :leg="leg" />
      </ag-div>
    </AgColumn>
  </AgRow>

  <ag-not-found v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import qs from "query-string";
import PriceSummary from "@/modules/FDG/components/PriceSummary.vue";
import { PAX_MAPPINGS } from "@/enums/PAX_MAPPINGS";
import { PAX_TYPES } from "@/ag-portal-common/constants/paxTypes";
import {
  tourBookingValidationSchema,
  tourBookingWithoutDataValidationSchema,
} from "@/modules/FDG/validations";
import { ValidationError } from "yup";
import {
  formatNumber,
  formatWordIntoCapitalize,
  getFormattedDateTime,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import NATIONALITIES from "@/ag-portal-common/constants/nationalities";
import { PAX_RELATIONS } from "@/ag-portal-common/constants/paxRelations";
import { PAX_TITLES } from "@/ag-portal-common/constants/paxTitles";
import {
  FORMAT_DD_MMM,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { FDGBookingConfirmRequestModel } from "@/modules/FDG/models/fdgBookingConfirm.request";
import { PATH } from "@/ag-portal-common/constants/path";
import { FDGBookingConfirmWithoutTravelerDetailsRequestModel } from "@/modules/FDG/models/fdgBookingConfirmWithoutTravelerDetails.request";
import {
  IFDGBookingDetail,
  IFDGBookingDetailItem,
} from "@/interfaces/fdgBookingDetail.interface";
import { IPriceSummary } from "@/modules/FDG/interface/priceSummary.interface";
import TripSummary from "@/modules/FDG/components/TripSummary.vue";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import HotelSummary from "@/modules/FDG/components/HotelSummary.vue";

const travelerInitialValues = {
  passportNumber: "",
  isPassportImageRequired: false,
  passportImage: null,
  passportRequired: false,
  passportExpiry: null,
  dob: null,
  title: null,
  firstName: "",
  lastName: "",
  type: "",
  documents: [],
  nationality: null,
  passengerRelation: null,
};

interface IFileDocument {
  title: string;
  file: File;
}

export default defineComponent({
  name: "BookingConfirmationForm",
  computed: {
    isFetchingFDGBookingDetail(): boolean {
      return this.$store.getters.isFetchingFDGBookingDetail;
    },
    BOOKING_TYPES() {
      return BOOKING_TYPES;
    },
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
    PAX_RELATIONS() {
      return PAX_RELATIONS;
    },
    fdgBookingDetail(): IFDGBookingDetail {
      return this.$store.getters.fdgBookingDetail;
    },
    isConfirmFDGBooking() {
      return this.$store.getters.isConfirmFDGBooking;
    },
    isConfirmFDGBookingWithoutTravelerDetails() {
      return this.$store.getters.isConfirmFDGBookingWithoutTravelerDetails;
    },
    PAX_TYPES() {
      return PAX_TYPES;
    },
    NATIONALITIES() {
      return NATIONALITIES;
    },
    getLegs(): IFDGBookingDetailItem[] {
      let fdgBookingDetail: IFDGBookingDetail =
        this.$store.getters.fdgBookingDetail;
      return fdgBookingDetail?.items || [];
    },
    getPriceInfo(): IPriceSummary[] {
      let fdgBookingDetail: IFDGBookingDetail | null =
        this.$store.getters.fdgBookingDetail;
      let startDate = fdgBookingDetail?.startDate || new Date();

      return [
        {
          info: `${getFormattedDateTime(startDate, FORMAT_DD_MMM)} | ${
            fdgBookingDetail?.items[0].itemName
          }`,
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.bookingPricePerPerson || ""
          )}`,
          isTotalPriceCell: false,
        },
        {
          info: `Passenger(s) Count x ${fdgBookingDetail?.numberOfGuests}`,
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.totalPrice || ""
          )}`,
          isTotalPriceCell: false,
        },
        {
          info: "PRICE YOU PAY",
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.totalPrice || ""
          )}`,
          isTotalPriceCell: true,
        },
      ];
    },
  },
  components: { HotelSummary, TripSummary, PriceSummary },
  data(): {
    formData: any;
    errors: any;
    enableFormFilling: boolean;
  } {
    return {
      enableFormFilling: false,
      errors: {},
      formData: {
        tourType: TOUR_TYPES.FLIGHTS_ONLY,
        bookingCustomer: {
          phone: "",
          email: "",
        },
        passengers: [],
      },
    };
  },
  methods: {
    formatWordIntoCapitalize,
    PAX_TITLES(type: PAX_MAPPINGS) {
      return PAX_TITLES.filter((item) => item.type === (type as string));
    },
    formattedDate(date: Date | null) {
      if (date) {
        return getFormattedDateTime(date, FORMAT_YYY_MM_DD);
      }
      return "";
    },
    async submitFormWithoutTravelerDetails() {
      this.errors = {};
      try {
        await tourBookingWithoutDataValidationSchema.validate(this.formData, {
          abortEarly: false,
        });
        let { fdgBookingDetail } = this.$store.getters;
        let payload: FDGBookingConfirmWithoutTravelerDetailsRequestModel = {
          booking_id: fdgBookingDetail.bookingId,
          contact_information: {
            contact_number: this.formData.bookingCustomer.phone,
            contact_email: this.formData.bookingCustomer.email,
          },
          no_of_passengers: fdgBookingDetail.numberOfGuests,
        };
        this.$store.dispatch(
          "confirmGroupFlightBookingWithoutTravelerDetails",
          {
            payload: payload,
            callback: () => this.$router.push(PATH.FDG_FLIGHTS),
          }
        );
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },

    async submitForm(e: { preventDefault: () => void }) {
      e.preventDefault();
      this.errors = {};
      try {
        await tourBookingValidationSchema.validate(this.formData, {
          abortEarly: false,
        });
        let { fdgBookingDetail } = this.$store.getters;
        let _formData = new FormData();
        for (const passenger of this.formData.passengers) {
          if (
            passenger.passportRequired &&
            this.formData.tourType === TOUR_TYPES.UMRAH
          ) {
            _formData.append(
              `${passenger.passportNumber}_passport_image`,
              passenger.passportImage
            );
          }
          for (let i = 0; i < passenger.documents.length; i++) {
            const document = passenger.documents[i];
            _formData.append(`${document.title}_${i}`, document.file);
          }
        }

        let payload: FDGBookingConfirmRequestModel = {
          booking_id: fdgBookingDetail.bookingId,
          contact_information: {
            contact_number: this.formData.bookingCustomer.phone,
            contact_email: this.formData.bookingCustomer.email,
          },
          passenger_details: [],
        };

        payload.passenger_details = this.formData.passengers.map(
          (passenger: any) => ({
            salutation: passenger.title,
            first_name: passenger.firstName,
            last_name: passenger.lastName,
            passenger_type: passenger.type,
            nationality: passenger.nationality,
            passport_no: passenger.passportRequired
              ? passenger.passportNumber
              : null,
            passport_expiry: passenger.passportRequired
              ? this.formattedDate(passenger.passportExpiry)
              : null,
            dob: this.formattedDate(passenger.dob),
            passenger_relation: passenger.passengerRelation,
            passport_image: passenger.isPassportImageRequired
              ? `${passenger.passportNumber}_passport_image`
              : null,
            documents: passenger.documents.map(
              (doc: IFileDocument, index: number) => ({
                title: doc.title,
                file: `${doc.title}_${index}`,
              })
            ),
          })
        );
        _formData.append("json_data", JSON.stringify(payload));
        this.$store.dispatch("confirmGroupFlightBooking", {
          payload: _formData,
          callback: () =>
            this.$router.push(
              fdgBookingDetail.tourType === TOUR_TYPES.FLIGHTS_ONLY
                ? PATH.FDG_FLIGHTS
                : PATH.FDG_UMRAH
            ),
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
  },
  async beforeMount() {
    await this.$store.dispatch(
      "fetchGroupFlightBookingDetail",
      this.$route.params.id
    );
    let fdgBookingDetail: IFDGBookingDetail =
      this.$store.getters.fdgBookingDetail;
    this.enableFormFilling =
      fdgBookingDetail?.tourType !== TOUR_TYPES.FLIGHTS_ONLY;
    let parsedQuery = qs.parse(window.location.search) as {
      adult: string;
      infant: string;
      child: string;
    };
    let stringedZero = "0";
    let adultCount = parseInt(parsedQuery?.adult || stringedZero);
    let childCount = parseInt(parsedQuery?.child || stringedZero);
    let infantCount = parseInt(parsedQuery?.infant || stringedZero);
    let totalCount = adultCount + childCount + infantCount;
    let passengers: Array<any> = [];
    for (let i = 0; i < totalCount; i++) {
      let paxType = "";
      if (adultCount) {
        paxType = PAX_MAPPINGS.ADULT;
        adultCount--;
      } else if (childCount) {
        paxType = PAX_MAPPINGS.CHILD;
        childCount--;
      } else {
        paxType = PAX_MAPPINGS.INFANT;
        infantCount--;
      }
      let passenger = {
        ...travelerInitialValues,
        type: paxType,
        passportRequired: fdgBookingDetail?.passportRequired || false,
        isPassportImageRequired:
          fdgBookingDetail?.passportRequired &&
          fdgBookingDetail.tourType === TOUR_TYPES.UMRAH,
        documents:
          fdgBookingDetail?.additionalDocuments.map((doc: IFileDocument) => ({
            title: doc,
            file: null,
          })) || [],
      };
      passengers.push(passenger);
    }
    this.formData.tourType = fdgBookingDetail.tourType;
    this.formData.passengers = passengers;
  },
});
</script>
