<template>
  <ToursList :tour-type="TOUR_TYPES.UMRAH" title="UMRAH / ZIARAT PACKAGES">
    <template #tourSummary="{ tourItem }">
      <ag-div class="overflow-x-scroll main_tour_info">
        <ag-div class="inner_row">
          <ag-div class="inner_tour_info">
            <ag-div class="head">Includes</ag-div>
            <ag-div class="type_wrap">
              <ag-div
                v-for="(item, index) in tourItem.inclusions"
                v-bind:key="index"
                class="combine_logo"
              >
                <AgImage :src="item.icon" />
                <ag-div class="type_value">{{ item.category }}</ag-div>
              </ag-div>
            </ag-div>
          </ag-div>
          <ag-div
            v-if="hotelItems(tourItem).length > 0"
            class="inner_tour_info"
          >
            <ag-div class="head">Distance</ag-div>
            <ag-div class="type_wrap">
              <ag-div
                v-for="(item, index) in hotelItems(tourItem)"
                v-bind:key="index"
                class="combine_logo d-flex"
              >
                <AgIcon
                  v-if="item?.hotelItemDetails.propertyCity === 'Makkah'"
                  class="margin_right_10"
                  name="u_d_kaaba_icon"
                />

                <AgIcon
                  v-if="item?.hotelItemDetails.propertyCity === 'Medina'"
                  name="u_d_madina_icon"
                />
                <ag-div class="type_value"
                  >{{ item?.hotelItemDetails.distance }}
                </ag-div>
              </ag-div>
            </ag-div>
          </ag-div>
        </ag-div>
        <ag-div class="inner_row">
          <ag-div class="inner_tour_info">
            <ag-div class="head">Route Info</ag-div>
            <ag-div class="route_info_wrapper">
              <ag-div
                v-for="(routeName, index) in routeNames(tourItem.items)"
                v-bind:key="index"
                class="route_info"
              >
                <ag-div>{{ routeName }}</ag-div>
                <AgIcon
                  v-if="index !== routeNames(tourItem.items).length - 1"
                  name="arrowRightGreen"
                />
              </ag-div>
            </ag-div>
          </ag-div>
        </ag-div>
      </ag-div>
    </template>
  </ToursList>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ToursList from "@/modules/FDG/components/ToursList.vue";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";
import { IFDG, IFDGItem } from "@/interfaces/fdg.interface";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";

export default defineComponent({
  name: "UmrahList",
  computed: {
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
  },
  methods: {
    hotelItems(fdg: IFDG) {
      return fdg.items.filter((item) => item.itemType === BOOKING_TYPES.HOTEL);
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },
    routeNames(tourItems: IFDGItem[]) {
      return tourItems
        .map((tourItem) => {
          if (tourItem.itemType === BOOKING_TYPES.FLIGHT)
            return [
              tourItem.flightItemDetails?.originCity,
              tourItem.flightItemDetails?.destinationCity,
            ];
          else if (tourItem.itemType === BOOKING_TYPES.HOTEL)
            return [tourItem.hotelItemDetails?.propertyCity];
          else return [];
        })
        .join(",")
        .split(",");
    },
  },
  components: { ToursList },
});
</script>
