import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgHotelBookingReview = _resolveComponent("AgHotelBookingReview")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_AgHotelBookingReview, {
        "checkin-date": _ctx.renderDate(_ctx.leg.hotelItemDetails?.checkIn),
        "checkout-date": _ctx.renderDate(_ctx.leg.hotelItemDetails?.checkOut),
        "hotel-address": _ctx.leg.hotelItemDetails?.propertyAddress,
        "hotel-logo": _ctx.leg.hotelItemDetails?.propertyImage,
        "hotel-name": _ctx.leg.hotelItemDetails?.propertyName,
        "no-of-night-stay-txt": _ctx.hotelNumberOfNightStayText,
        "trip-type": _ctx.leg.itemType,
        "room-type": "N/A",
        "test-id": ""
      }, null, 8 /* PROPS */, ["checkin-date", "checkout-date", "hotel-address", "hotel-logo", "hotel-name", "no-of-night-stay-txt", "trip-type"])
    ]),
    _: 1 /* STABLE */
  }))
}