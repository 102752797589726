import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgTripSummary = _resolveComponent("AgTripSummary")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_AgTripSummary, {
        "airline-logo": _ctx.leg.flightItemDetails?.airlineLogo,
        "arrival-date": _ctx.renderDate(_ctx.leg.flightItemDetails?.arrivalDatetime),
        "departure-date": _ctx.renderDate(_ctx.leg.flightItemDetails?.departureDatetime),
        "from-destination-code": _ctx.leg.flightItemDetails?.originIATACode,
        "from-destination-name": _ctx.leg.flightItemDetails?.originCity,
        "from-destination-time": 
        _ctx.renderDate(_ctx.leg.flightItemDetails?.departureDatetime, _ctx.FORMAT_HH_SS)
      ,
        "to-destination-code": _ctx.leg.flightItemDetails?.destinationIATACode,
        "to-destination-name": _ctx.leg.flightItemDetails?.destinationCity,
        "to-destination-time": 
        _ctx.renderDate(_ctx.leg.flightItemDetails?.arrivalDatetime, _ctx.FORMAT_HH_SS)
      ,
        "trip-type": _ctx.leg.itemType,
        "test-id": ""
      }, null, 8 /* PROPS */, ["airline-logo", "arrival-date", "departure-date", "from-destination-code", "from-destination-name", "from-destination-time", "to-destination-code", "to-destination-name", "to-destination-time", "trip-type"]),
      _createVNode(_component_ag_div, null, {
        default: _withCtx(() => [
          _createVNode(_component_ag_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ag_heading, {
                variant: "h3",
                title: "Flight Number:",
                style: {"margin-left":"10px","margin-right":"10px"}
              }),
              _createElementVNode("p", null, _toDisplayString(_ctx.leg.flightItemDetails?.flightNumber), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}