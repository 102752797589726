<template>
  <ag-card>
    <AgTripSummary
      :airline-logo="leg.flightItemDetails?.airlineLogo"
      :arrival-date="renderDate(leg.flightItemDetails?.arrivalDatetime)"
      :departure-date="renderDate(leg.flightItemDetails?.departureDatetime)"
      :from-destination-code="leg.flightItemDetails?.originIATACode"
      :from-destination-name="leg.flightItemDetails?.originCity"
      :from-destination-time="
        renderDate(leg.flightItemDetails?.departureDatetime, FORMAT_HH_SS)
      "
      :to-destination-code="leg.flightItemDetails?.destinationIATACode"
      :to-destination-name="leg.flightItemDetails?.destinationCity"
      :to-destination-time="
        renderDate(leg.flightItemDetails?.arrivalDatetime, FORMAT_HH_SS)
      "
      :trip-type="leg.itemType"
      test-id=""
    />
    <ag-div>
      <ag-row>
        <ag-heading
          variant="h3"
          title="Flight Number:"
          style="margin-left: 10px; margin-right: 10px"
        />
        <p>{{ leg.flightItemDetails?.flightNumber }}</p>
      </ag-row>
    </ag-div>
  </ag-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IFDGBookingDetailItem } from "@/interfaces/fdgBookingDetail.interface";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY,
  FORMAT_HH_SS,
} from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "TripSummary",
  props: {
    leg: {
      type: Object as PropType<IFDGBookingDetailItem>,
    },
  },
  computed: {
    FORMAT_HH_SS() {
      return FORMAT_HH_SS;
    },
  },
  methods: {
    renderDate: (date: Date, format = FORMAT_DD_MMM_YYYY) => {
      return date ? getFormattedDateTime(date, format) : "";
    },
  },
});
</script>
