<template>
  <ag-card>
    <AgRow class="d-flex justify-content-between align-items-center">
      <AgColumn>
        <ag-heading title="Select City" variant="label"></ag-heading>
        <AgAutoComplete
          item-label="label"
          v-model="searchValue"
          icon="calendar"
          :items="cities"
          @selectItem="onSelectCity"
        >
          <template #dropdownItem="{ itemProps, itemData }">
            <AgTextFieldDropDownItem
              v-bind="itemProps"
              @click="onSelectCity(itemData.id)"
            >
              <AgDiv class="flightDropdownWrap">
                <AgIcon
                  class="flight_icon_wrap"
                  test-id=""
                  name="umrah"
                ></AgIcon>
                <AgDiv class="airport_name">
                  {{ itemData.label }}
                  <AgDiv>{{ itemData.country }}</AgDiv>
                </AgDiv>
              </AgDiv>
            </AgTextFieldDropDownItem>
          </template>
        </AgAutoComplete>
      </AgColumn>

      <AgColumn>
        <ag-calendar
          label="Check In"
          v-model="checkin_date"
          :min-date="new Date()"
          :date="checkin_date"
          calendar-name="checkin"
          IconName="calendar"
          @onChangeDate="
            function changeDate(date: Date) {
              checkin_date = date;
            }
          "
        />
      </AgColumn>

      <AgColumn>
        <ag-calendar
          label="Check Out"
          v-model="checkout_date"
          :min-date="getMinCheckoutDate"
          :date="checkout_date"
          calendar-name="checkout"
          IconName="calendar"
          @onChangeDate="
            function changeDate(date: Date) {
              checkout_date = date;
            }
          "
        />
      </AgColumn>

      <AgColumn>
        <ag-heading title="Traveler" variant="label"></ag-heading>

        <AgTravelerCountDropdown
          test-id=""
          :label="`Travelers - ${adultCount}`"
        >
          <template #Items>
            <AgTravelerCountItem
              name="adultCount"
              :count="adultCount"
              icon="adultIcon"
              @update:adultCount="updateAdultCount"
              label="Adult (12+)"
              test-id=""
            ></AgTravelerCountItem>
          </template>
        </AgTravelerCountDropdown>
      </AgColumn>
    </AgRow>

    <AgRow class="d-flex justify-content-center">
      <AgColumn class="text-center">
        <AGButton variant="primary" @click="searchHotels"
          >Search Hotels</AGButton
        >
      </AgColumn>
    </AgRow>
  </ag-card>
  <ag-card v-if="validateItenary" style="padding: 10px">
    <ag-div
      v-for="(booking, bookingKey) in ValidatedItenaryResp"
      :key="bookingKey"
    >
      <ag-hotel-selected-info
        style="margin-top: 1cm"
        :checkinDate="booking.checkInDate"
        :checkoutDate="booking.checkOutDate"
        :noOfNight="booking.noOfNight"
        :totalCost="booking.totalCost"
        :hotelName="booking.hotelName"
        :roomType="booking.roomType"
        :travelers="booking.travellers"
      />
    </ag-div>
    <AgRow
      class="d-flex justify-content-center"
      v-if="validateItenary && ValidatedItenaryResp"
    >
      <AgColumn class="text-center">
        <AGButton
          variant="primary"
          class="margin_bottom_10"
          @click="openModalForPreview()"
          test-id=""
          >Preview</AGButton
        >
      </AgColumn>
    </AgRow>
  </ag-card>
  <ag-card v-show="responseData?.hotels_detail" style="padding: 10px">
    <ag-div
      v-for="hotel in responseData?.hotels_detail"
      :key="hotel.hotel_id"
      class="hotel-details"
      style="border-color: grey"
    >
      <ag-card>
        <ag-hotel-info
          :hotelName="hotel.hotel_name"
          :hotelDistance="hotel.hotel_meta.distance"
          :cityName="hotel.address"
        >
          <ag-row style="margin: bottom 0.5cm">
            <ag-hotel-info-card
              v-for="room in hotel.rooms_detail"
              :key="room.room_id"
              class="room-details"
              :singleBedRates="
                room.room_rate.per_night_per_bed + ' / Per Night'
              "
              :doubleBedRates="
                room.room_rate.per_night_per_room + ' / Per Night'
              "
              :quantity="room.room_name + ' x' + room.room_rate.room_count"
              :price="hotel.hotel_currency + ' ' + room.room_rate.final_price"
              :stay="room.total_nights + ' Night(s)'"
            >
              <AGButton
                class="full-width"
                test-id=""
                @click="openModal(room, hotel)"
                >Book Room</AGButton
              >
            </ag-hotel-info-card>
          </ag-row>
        </ag-hotel-info>
      </ag-card>
    </ag-div>
  </ag-card>

  <ag-not-found
    v-show="!responseData?.hotels_detail"
    description="modify your search to get FIT results"
  />

  <PackageDetailModal
    @closeModal="closeModal"
    :isOpen="showPackageDetailModal"
    :hotel-array="dynamicBookings"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import FITService from "@/modules/FIT/services/fit.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import PackageDetailModal from "@/modules/FIT/components/PackageDetailModal.vue";
interface HotelBooking {
  roomID: string;
  checkInDate: string;
  checkOutDate: string;
  noOfNight: string;
  travellers: number;
  roomType: string;
  totalCost: string;
  hotelName: string;
  hotelId: string;
  roomCount: number;
  onlyPrice: number;
}

export default defineComponent({
  name: "HotelsAndUmrah",
  props: {},
  data() {
    return {
      searchValue: "",
      selectedCityId: "",
      adultCount: 1,
      showResponse: false,
      validateItenary: false,
      ValidatedItenaryResp: [] as {
        checkInDate: string;
        checkOutDate: string;
        noOfNight: number;
        totalCost: number;
        hotelName: string;
        roomType: string;
        travellers: number;
      }[],
      showPackageDetailModal: false,
      selectedRoom: null,
      responseData: null,
      checkin_date: null as Date | null,
      checkout_date: null as Date | null,
      hotelDataObject: {
        roomID: "",
        checkInDate: "",
        checkOutDate: "",
        noOfNight: "",
        travellers: 1,
        roomType: "",
        totalCost: "",
        hotelName: "",
        hotelId: "",
        roomCount: 1,
        onlyPrice: 1,
      } as HotelBooking,
      dynamicBookings: {} as Record<string, HotelBooking>,
      cities: [
        {
          label: "Macca",
          value: "Macca",
          country: "Saudia",
          id: 2,
        },
        {
          label: "Madina",
          value: "Madina",
          country: "Saudia",
          id: 1,
        },
      ],
    };
  },
  components: {
    PackageDetailModal,
  },
  computed: {
    getMinCheckoutDate(): Date {
      if (this.checkin_date) {
        const checkinDate = new Date(this.checkin_date);
        if (!isNaN(checkinDate.getTime())) {
          const minDate = new Date(checkinDate);
          minDate.setDate(minDate.getDate() + 1);
          return minDate;
        }
      }

      return new Date();
    },
  },
  methods: {
    closeModal() {
      this.showPackageDetailModal = false;
      this.dynamicBookings = {};
    },
    getItenariesOnHomePage() {
      if (localStorage.getItem("itenary")) {
        //
        this.validateItenary = true;
        this.ValidatedItenaryResp = [];
        if (JSON.parse(localStorage.getItem("itenary") || "[]").length > 0) {
          this.ValidatedItenaryResp = JSON.parse(
            localStorage.getItem("itenary") || "[]"
          );
        }
      } else {
        return null;
      }
    },
    openModal(room: any, hotel: any) {
      this.showPackageDetailModal = true;
      this.selectedRoom = room;
      this.pushToNextPage(room, hotel);
    },
    openModalForPreview() {
      this.showPackageDetailModal = true;
    },
    pushToNextPage(room: any, hotel: any) {
      // Create a new booking object with existing and new values
      this.dynamicBookings = {}; // Empty the dynamicBookings object
      const newBooking = {
        roomID: room.room_id,
        checkInDate: getFormattedDateTime(
          this.checkin_date || new Date(),
          FORMAT_YYY_MM_DD
        ),
        checkOutDate: getFormattedDateTime(
          this.checkout_date || new Date(),
          FORMAT_YYY_MM_DD
        ),
        noOfNight: room.total_nights + " NIGHT(S) STAY",
        travellers: this.adultCount,
        roomType: room.room_name + " x" + room.room_rate.room_count,
        hotelId: hotel.hotel_id,
        totalCost: hotel.hotel_currency + " " + room.room_rate.final_price,
        hotelName: hotel.hotel_name,
        roomCount: room.room_rate.room_count,
        onlyPrice: room.room_rate.final_price,
      };
      const nextKey = `booking${Object.keys(this.dynamicBookings).length + 1}`;
      this.dynamicBookings[nextKey] = newBooking;
    },

    updateAdultCount(count: number) {
      this.adultCount = count;
    },
    onSelectCity(id: string) {
      this.selectedCityId = id;
    },

    assignCurrentDateIfNull() {
      if (this.checkin_date === null) {
        this.checkin_date = new Date(); // Assign current date to checkin_date
      }
    },
    async searchHotels() {
      try {
        this.showResponse = false; // shuru me kuch aara to ura de
        this.responseData = null; // shuru me kuch aara to ura de
        this.getItenariesOnHomePage();

        if (this.selectedCityId !== null && this.selectedCityId !== "") {
          const checkinFormattedDate = getFormattedDateTime(
            this.checkin_date || new Date(),
            FORMAT_YYY_MM_DD
          );

          const checkoutFormattedDate = getFormattedDateTime(
            this.checkout_date || new Date(),
            FORMAT_YYY_MM_DD
          );

          const fitServ = new FITService();
          const response = (await fitServ.fetchFitHotel(
            this.selectedCityId,
            checkinFormattedDate,
            checkoutFormattedDate,
            this.adultCount
          )) as IAGResponse<any>;

          this.responseData = response.data.data;
          // means data khaali ha k nai wo check krle
          if (response.data.data.hotels_detail != "") {
            this.showResponse = true;
          } else {
            this.showResponse = false;
          }
        } else {
          // place selected hi nai ha koi
          notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description = "Please select A City";
          notificationService.triggerNotification();
          this.showResponse = false;
        }
      } catch (error) {
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "something went wrong";
        notificationService.triggerNotification();
        this.showResponse = false;
      }
    },
  },
  watch: {
    checkin_date(newDate: Date | null): void {
      if (newDate !== null) {
        const checkinDate = new Date(newDate);
        let newCheckoutDate = new Date(this.checkout_date || checkinDate);
        // 1 din sa ziada ka checkin farq ha tow gtg
        // else plus 1 kardo
        if (newCheckoutDate <= checkinDate) {
          newCheckoutDate.setDate(checkinDate.getDate() + 1);
        }
        this.checkout_date = newCheckoutDate;
      }
    },
    ValidatedItenaryResp(newVal) {
      this.validateItenary = newVal;
    },
  },
  created() {
    this.assignCurrentDateIfNull();
    this.getItenariesOnHomePage();
  },
  mounted() {
    this.assignCurrentDateIfNull();
    this.adultCount;
  },
});
</script>

<style>
.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}
</style>
