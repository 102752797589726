import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconBox = _resolveComponent("AgIconBox")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_chip = _resolveComponent("ag-chip")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgPropertyReview = _resolveComponent("AgPropertyReview")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!
  const _component_AgDiv = _resolveComponent("AgDiv")!

  return (_openBlock(), _createBlock(_component_AgDiv, {
    class: "widget_border margin_top_20 margin_bottom_20",
    "test-id": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_accordion, {
        "panel-value": [1, 2],
        class: "flight_accordion_wrap margin_bottom_0",
        title: "flight acc 2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_accordion_panel, null, {
            flightInfo: _withCtx(() => [
              _createVNode(_component_AgRow, { "test-id": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgColumn, {
                    lg: "3",
                    md: "4",
                    sm: "12",
                    "test-id": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgIconBox, {
                        "sub-title": _ctx.ietnaryDetail.itemName,
                        title: _ctx.ietnaryDetail.itemType,
                        icon: "hotelIcon",
                        "test-id": ""
                      }, null, 8 /* PROPS */, ["sub-title", "title"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_AgColumn, {
                    lg: "9",
                    md: "8",
                    sm: "12",
                    "test-id": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgIconBox, {
                        "sub-title": `Distance - ${_ctx.ietnaryDetail.hotelItemDetails?.distance}`,
                        title: _ctx.ietnaryDetail.hotelItemDetails?.propertyName,
                        "test-id": ""
                      }, null, 8 /* PROPS */, ["sub-title", "title"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            tabSection: _withCtx(() => [
              _createVNode(_component_AgPropertyReview, {
                "checkin-date": 
              _ctx.formattedDayAndDate(_ctx.ietnaryDetail.hotelItemDetails?.checkIn)
            ,
                "checkout-date": 
              _ctx.formattedDayAndDate(_ctx.ietnaryDetail.hotelItemDetails?.checkOut)
            ,
                disableHeading: true,
                "hotel-name": _ctx.ietnaryDetail.hotelItemDetails?.propertyName,
                "hotel-thumb": _ctx.ietnaryDetail.hotelItemDetails?.images[0] || '',
                "no-of-night": _ctx.numberOfNights,
                "test-id": ""
              }, {
                hotelImages: _withCtx(() => [
                  _createVNode(_component_ag_div, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_heading, {
                        title: "Includes",
                        variant: "h3"
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ietnaryDetail.hotelItemDetails
                    ?.facilities, (facility, index) => {
                        return (_openBlock(), _createBlock(_component_ag_chip, {
                          key: index,
                          class: "margin_right_10"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(facility), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["checkin-date", "checkout-date", "hotel-name", "hotel-thumb", "no-of-night"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}