import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_FlightItenary = _resolveComponent("FlightItenary")!
  const _component_HotelItenary = _resolveComponent("HotelItenary")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_radio_item = _resolveComponent("ag-radio-item")!
  const _component_ag_chip = _resolveComponent("ag-chip")!
  const _component_ag_radio = _resolveComponent("ag-radio")!
  const _component_AgTravelerCountItem = _resolveComponent("AgTravelerCountItem")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_heading, {
      title: _ctx.fdgDetail?.title,
      class: "bg_heading_wrap",
      variant: "h1"
    }, null, 8 /* PROPS */, ["title"]),
    (_ctx.isFetchingFDGDetail)
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
      : (!_ctx.isFetchingFDGDetail && _ctx.fdgDetail)
        ? (_openBlock(), _createBlock(_component_ag_div, {
            key: 1,
            class: "margin_top_20"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_column, {
                    md: "9",
                    sm: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            title: "Itinerary Details",
                            variant: "h2"
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fdgDetail.items, (item) => {
                            return (_openBlock(), _createBlock(_component_ag_div, {
                              key: item.id
                            }, {
                              default: _withCtx(() => [
                                (item.itemType === _ctx.BOOKING_TYPES.FLIGHT)
                                  ? (_openBlock(), _createBlock(_component_FlightItenary, {
                                      key: 0,
                                      ietnaryDetail: item
                                    }, null, 8 /* PROPS */, ["ietnaryDetail"]))
                                  : _createCommentVNode("v-if", true),
                                (item.itemType === _ctx.BOOKING_TYPES.HOTEL)
                                  ? (_openBlock(), _createBlock(_component_HotelItenary, {
                                      key: 1,
                                      ietnaryDetail: item
                                    }, null, 8 /* PROPS */, ["ietnaryDetail"]))
                                  : _createCommentVNode("v-if", true)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _renderSlot(_ctx.$slots, "inclusions", { fdgDetail: _ctx.fdgDetail }),
                      (_ctx.fdgDetail?.rules?.length)
                        ? (_openBlock(), _createBlock(_component_ag_card, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ag_heading, {
                                title: "Rules",
                                variant: "h2"
                              }),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fdgDetail?.rules, (rule, i) => {
                                return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(rule.trim("")), 1 /* TEXT */))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 3 /* FORWARDED */
                  }),
                  _createVNode(_component_ag_column, {
                    md: "3",
                    sm: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            title: "Select Fare",
                            variant: "h3"
                          }),
                          _createVNode(_component_ag_heading, {
                            title: "Please select any of the given Fare(s)",
                            variant: "p"
                          }),
                          _createVNode(_component_ag_radio, {
                            modelValue: _ctx.selectedBookingId,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedBookingId) = $event))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fdgDetail.bookingOptions, (bookingOption) => {
                                return (_openBlock(), _createBlock(_component_ag_heading, {
                                  key: bookingOption.id,
                                  class: _normalizeClass(`select_fare_wrap ${
                _ctx.selectedBookingId === bookingOption.id ? 'active' : ''
              }`),
                                  variant: "label"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ag_div, { class: "flex-wrap d-flex align-items-center" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ag_radio_item, {
                                          value: bookingOption.id,
                                          name: "selectedBookingId"
                                        }, null, 8 /* PROPS */, ["value"]),
                                        _createVNode(_component_ag_heading, {
                                          title: `${bookingOption.currency} ${_ctx.formatNumber(
                    bookingOption.price
                  )} (${bookingOption.option_name})`,
                                          class: "margin_bottom_0",
                                          variant: "b"
                                        }, null, 8 /* PROPS */, ["title"])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */),
                                    _createVNode(_component_ag_chip, { color: "success" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(bookingOption.remainingQuantity) + " Available ", 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["modelValue"]),
                          _createVNode(_component_ag_heading, {
                            title: "Note: Rates of Child / Infant may vary (possibly\n            lesser). The final price can be checked with the FDG team after\n            booking. (No amount will be deducted when booking)",
                            variant: "p"
                          }),
                          _createVNode(_component_ag_div, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ag_heading, {
                                title: _ctx.paxSelectionHeading,
                                variant: "h3"
                              }, null, 8 /* PROPS */, ["title"]),
                              _createVNode(_component_ag_heading, {
                                title: _ctx.paxSelectionDescription,
                                variant: "p"
                              }, null, 8 /* PROPS */, ["title"]),
                              _createVNode(_component_ag_div, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AgTravelerCountItem, {
                                    count: _ctx.adultCount,
                                    icon: "adultIcon",
                                    label: "Adult",
                                    name: "AdultCount",
                                    "test-id": "",
                                    "onUpdate:AdultCount": _ctx.updateAdultCount
                                  }, null, 8 /* PROPS */, ["count", "onUpdate:AdultCount"]),
                                  _createVNode(_component_AgTravelerCountItem, {
                                    count: _ctx.childCount,
                                    hint: "(2 to less than 12)",
                                    icon: "childrenIcon",
                                    label: "Children",
                                    name: "ChildCount",
                                    "test-id": "",
                                    "onUpdate:ChildCount": _ctx.updateChildCount
                                  }, null, 8 /* PROPS */, ["count", "onUpdate:ChildCount"]),
                                  _createVNode(_component_AgTravelerCountItem, {
                                    count: _ctx.infantCount,
                                    hint: "(0 to less then 2)",
                                    icon: "infantIcon",
                                    label: "Infants",
                                    name: "InfantCount",
                                    "test-id": "",
                                    "onUpdate:InfantCount": _ctx.updateInfantCount
                                  }, null, 8 /* PROPS */, ["count", "onUpdate:InfantCount"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_ag_div, { class: "margin_top_20 text-center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_g_button, {
                                    "is-loading": _ctx.isInitiatingFDGBooking,
                                    onClick: _ctx.onBookGroup
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Book Now ")
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["is-loading", "onClick"])
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 3 /* FORWARDED */
              })
            ]),
            _: 3 /* FORWARDED */
          }))
        : (_openBlock(), _createBlock(_component_ag_not_found, {
            key: 2,
            description: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND
          }, null, 8 /* PROPS */, ["description"]))
  ], 64 /* STABLE_FRAGMENT */))
}