<template>
  <AgDiv class="widget_border margin_top_20 margin_bottom_20" test-id="">
    <ag-accordion
      :panel-value="[1, 2]"
      class="flight_accordion_wrap margin_bottom_0"
      title="flight acc 2"
    >
      <ag-accordion-panel>
        <template #flightInfo>
          <AgRow test-id="">
            <AgColumn lg="3" md="4" sm="12" test-id="">
              <AgIconBox
                :sub-title="ietnaryDetail.itemName"
                :title="ietnaryDetail.itemType"
                icon="flightDepartureIcon"
                test-id=""
              ></AgIconBox>
            </AgColumn>
            <AgColumn lg="9" md="8" sm="12" test-id="">
              <AgFlightDetailCard
                :airline-code="
                  ietnaryDetail?.flightItemDetails?.segment[0]?.flightNumber
                "
                :airline-logo="
                  ietnaryDetail?.flightItemDetails?.segment[0]?.airline?.logo
                "
                :airline-name="
                  ietnaryDetail?.flightItemDetails?.segment[0]?.airline?.name
                "
                :duration="
                  getDurationText(
                    ietnaryDetail.flightItemDetails?.departureDatetime,
                    ietnaryDetail.flightItemDetails?.arrivalDatetime
                  )
                "
                :fare-info="true"
                :from-destination="
                  ietnaryDetail.flightItemDetails?.originIATACode
                "
                :from-time="
                  formattedTime(
                    ietnaryDetail.flightItemDetails?.departureDatetime
                  )
                "
                :to-destination="
                  ietnaryDetail.flightItemDetails?.destinationIATACode
                "
                :to-time="
                  formattedTime(
                    ietnaryDetail.flightItemDetails?.arrivalDatetime
                  )
                "
                test-id=""
              >
              </AgFlightDetailCard>
            </AgColumn>
          </AgRow>
        </template>
        <template #tabSection>
          <ag-div
            v-for="(segment, i) in ietnaryDetail.flightItemDetails?.segment"
            v-bind:key="i"
          >
            <AgHeading
              :title="`Departure - ${formattedDayAndDate(
                segment.departureDatetime
              )}`"
              class="flight_info_green_box margin_top_20"
              test-id=""
              variant="h4"
            ></AgHeading>
            <AgFlightTerminalInfo
              :destination-airport-name="segment.destination.airport"
              :destination-city="segment.destination.city"
              :destination-time="formattedTime(segment.arrivalDatetime)"
              :duration="
                getDurationText(
                  segment.departureDatetime,
                  segment.arrivalDatetime
                )
              "
              :origin-airport-name="segment.origin.airport"
              :origin-city="segment.origin.city"
              :origin-time="formattedTime(segment.departureDatetime)"
              test-id=""
            ></AgFlightTerminalInfo>
          </ag-div>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IFDGItem } from "@/interfaces/fdg.interface";
import {
  getDayFromDate,
  getDurationText,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_HH_SS_A,
  FORMAT_MMMM_DD_YYYY,
} from "@/ag-portal-common/constants/dateTimeFormats";
import AgDuration from "@/ag-portal-common/components/AgDuration.vue";
import { DAYS } from "@/ag-portal-common/enums/DAYS";

export default defineComponent({
  name: "FlightItenary",
  computed: {
    AgDuration() {
      return AgDuration;
    },
  },
  props: {
    ietnaryDetail: {
      type: Object as PropType<IFDGItem>,
    },
  },
  methods: {
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS_A);
    },
    formattedDayAndDate(date: Date) {
      return (
        DAYS[getDayFromDate(date)] +
        ", " +
        getFormattedDateTime(date, FORMAT_MMMM_DD_YYYY)
      );
    },
    getDurationText,
  },
});
</script>
