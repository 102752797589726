import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgImage = _resolveComponent("AgImage")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_TourDetail = _resolveComponent("TourDetail")!

  return (_openBlock(), _createBlock(_component_TourDetail, {
    "pax-selection-description": 
      _ctx.FDG_DETAIL_VIEW_TEXTS.UMRAH_PAX_SELECTION_DESCRIPTION
    ,
    "pax-selection-heading": _ctx.FDG_DETAIL_VIEW_TEXTS.UMRAH_PAX_SELECTION_HEADING
  }, {
    inclusions: _withCtx(({ fdgDetail }) => [
      _createVNode(_component_ag_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ag_heading, {
            title: "Includes",
            variant: "h3"
          }),
          _createVNode(_component_ag_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fdgDetail.inclusions, (item, index) => {
                return (_openBlock(), _createBlock(_component_ag_column, {
                  key: index,
                  md: "4",
                  sm: "2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_div, { class: "includes_wrap" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_div, { class: "include_icon" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AgImage, {
                              src: item.icon
                            }, null, 8 /* PROPS */, ["src"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_ag_div, { class: "include_info" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.category), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */),
                            _createVNode(_component_ag_div, { class: "value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.shortDescription), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["pax-selection-description", "pax-selection-heading"]))
}