<template>
  <TourDetail
    :pax-selection-description="
      FDG_DETAIL_VIEW_TEXTS.UMRAH_PAX_SELECTION_DESCRIPTION
    "
    :pax-selection-heading="FDG_DETAIL_VIEW_TEXTS.UMRAH_PAX_SELECTION_HEADING"
  >
    <template #inclusions="{ fdgDetail }">
      <ag-card>
        <ag-heading title="Includes" variant="h3" />
        <ag-row>
          <ag-column
            v-for="(item, index) in fdgDetail.inclusions"
            v-bind:key="index"
            md="4"
            sm="2"
          >
            <ag-div class="includes_wrap">
              <ag-div class="include_icon">
                <AgImage :src="item.icon" />
              </ag-div>
              <ag-div class="include_info">
                <ag-div class="label">{{ item.category }}</ag-div>
                <ag-div class="value">{{ item.shortDescription }}</ag-div>
              </ag-div>
            </ag-div>
          </ag-column>
        </ag-row>
      </ag-card>
    </template>
  </TourDetail>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TourDetail from "@/modules/FDG/components/TourDetail.vue";
import { FDG_DETAIL_VIEW_TEXTS } from "@/modules/FDG/constants";

export default defineComponent({
  name: "UmrahDetail",
  computed: {
    FDG_DETAIL_VIEW_TEXTS() {
      return FDG_DETAIL_VIEW_TEXTS;
    },
  },
  components: { TourDetail },
});
</script>
