<template>
  <ag-modal :is-open="isOpen" modalWidth="70%">
    <template #header>
      <v-card-title>Selected Hotel for FIT</v-card-title>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="$emit('closeModal')"
        >Close</a-g-button
      >
    </template>
    <template #body>
      <div v-if="emptyHaiKNai > 0">
        <div
          v-for="(booking, bookingKey) in getAllBookingsToShow()"
          :key="bookingKey"
        >
          <ag-hotel-selected-info
            :checkinDate="booking.checkInDate"
            :checkoutDate="booking.checkOutDate"
            :noOfNight="booking.noOfNight"
            :totalCost="booking.totalCost"
            :hotelName="booking.hotelName"
            :roomType="booking.roomType"
            :travelers="booking.travellers"
          />
        </div>
      </div>
      <div v-else>
        <div
          v-for="(booking, bookingKey) in getCurrentBooking()"
          :key="bookingKey"
        >
          <ag-hotel-selected-info
            :checkinDate="booking.checkInDate"
            :checkoutDate="booking.checkOutDate"
            :noOfNight="booking.noOfNight"
            :totalCost="booking.totalCost"
            :hotelName="booking.hotelName"
            :roomType="booking.roomType"
            :travelers="booking.travellers"
          />
        </div>
      </div>
      <AgTextarea
        rows="3"
        label="Type Your Query"
        v-model="query"
        :value="query"
      >
      </AgTextarea>
    </template>
    <template #footer>
      <a-g-button
        type="button"
        class="mr-10 text-uppercase"
        @click="getAllBookingToEmail"
      >
        Add Next Itinerary</a-g-button
      >
      <a-g-button
        type="button"
        variant="outlined"
        @click="sendEmail"
        class="text-uppercase"
      >
        Continue by sending email</a-g-button
      >
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FITService from "@/modules/FIT/services/fit.service";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { PATH } from "@/ag-portal-common/constants/path";

export default defineComponent({
  name: "PackageDetailModal",
  props: {
    isOpen: {
      type: Boolean,
    },
    hotelArray: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    emptyHaiKNai() {
      const len = JSON.parse(localStorage.getItem("itenary") || "[]").length;
      return len;
    },
  },

  data() {
    return {
      query: "",
      // isme itenary ka data laoga jo save karaya
      itenary: [] as {
        checkInDate: string;
        checkOutDate: string;
        noOfNight: number;
        totalCost: number;
        hotelName: string;
        roomType: string;
        travellers: number;
      }[],
      // isme current wali ka data laoga
      currentIternary: [] as {
        checkInDate: string;
        checkOutDate: string;
        noOfNight: number;
        totalCost: number;
        hotelName: string;
        roomType: string;
        travellers: number;
      }[],
      // this i have used k me isko show karaoga concatenated if any
      concatenatedArray: [] as {
        checkInDate: string;
        checkOutDate: string;
        noOfNight: number;
        totalCost: number;
        hotelName: string;
        roomType: string;
        travellers: number;
      }[],
    };
  },
  mounted() {
    // Check if local storage has items and update itenary array
    const localStorageItenary = JSON.parse(
      localStorage.getItem("itenary") || "[]"
    );
    if (localStorageItenary.length > 0) {
      this.itenary = localStorageItenary;
      this.currentIternary = [];
    } else {
      // If it's an empty array, remove the "itenary" key from LocalStorage
      localStorage.removeItem("itenary");
    }
  },
  methods: {
    getAllBookingToEmail() {
      // add itenary button k lia
      const keys = Object.keys(this.hotelArray);
      this.itenary = [];
      if (keys.length > 0) {
        const lastBookingKey = keys[keys.length - 1];
        if (lastBookingKey) {
          this.itenary = JSON.parse(localStorage.getItem("itenary") || "[]"); // fill karo
          if (this.itenary) {
            this.itenary.push(this.hotelArray[lastBookingKey]); // last wali daalow
            localStorage.setItem("itenary", JSON.stringify(this.itenary)); // then sett kardo
            this.$emit("closeModal"); /// modal band krdo
            notificationService.type = NOTIFICATION_TYPES.SUCCESS;
            notificationService.description = "Itenary Added";
            notificationService.triggerNotification();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          return this.itenary;
        }
      } else {
        return [];
      }
    },
    getAllBookingsToShow() {
      this.itenary = [];
      this.itenary = JSON.parse(localStorage.getItem("itenary") || "[]"); // fill karo
      if (this.itenary) {
        localStorage.setItem("itenary", JSON.stringify(this.itenary)); // then sett kardo
        this.getCurrentBooking();
        this.concatenatedArray = []; // first empty then assign
        this.concatenatedArray = [...this.itenary, ...this.currentIternary];
      }
      return this.concatenatedArray;
    },
    getCurrentBooking() {
      const keys = Object.keys(this.hotelArray);
      if (keys.length > 0) {
        const lastBookingKey = keys[keys.length - 1];
        if (lastBookingKey) {
          this.currentIternary.splice(0); // khaali krdo
          this.currentIternary.push(this.hotelArray[lastBookingKey]);
          return this.currentIternary;
        }
      }
      return [];
    },
    async sendEmail() {
      if (this.query.trim().length !== 0) {
        this.getAllBookingsToShow();
        localStorage.setItem("itenary", JSON.stringify(this.concatenatedArray));
        const itineraryArray = JSON.parse(
          localStorage.getItem("itenary") || "[]"
        );

        const itineraryInfoArray = [];
        for (const booking of itineraryArray) {
          const itineraryInfo = {
            hotel_id: booking.hotelId,
            room_id: booking.roomID,
            total_passengers: booking.travellers,
            check_in: booking.checkInDate,
            check_out: booking.checkOutDate,
            total_selling_price: booking.onlyPrice,
            number_of_rooms: booking.roomCount,
          };
          itineraryInfoArray.push(itineraryInfo);
        }

        const service = new FITService();
        const payload = {
          itinerary_info: itineraryInfoArray,
          request_message: this.query,
        };

        await service.fitSendEmailRequest(payload);
        this.query = ""; // empty krdo once sent
        setTimeout(() => {
          this.$router.push(PATH.DASHBOARD);
        }, 1500);
        this.$emit("closeModal");
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Query cant be empty";
        notificationService.triggerNotification();
      }
    },
  },
});
</script>
